<template>
    <div>
        <DatabaseActions />
    </div>
</template>

<script>
import DatabaseActions from '../../components/ClientPage/Actions/DatabaseActions.vue'
export default {
    name: 'ActionsRecorded',
    components: {
        DatabaseActions
    }
}
</script>

<style>
</style>