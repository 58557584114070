<template>
    <div class="container border-top p-3 d-sm-flex d-block text-sm-start text-center">
        <div class="container h5 px-4 align-items-center w-100 my-2 mb-4">Designed by João Denari, 2022</div>      
        <a  href="https://github.com/jdenari" 
            class="" 
            target="_blank" 
        >
            <img src="../assets/contact-icons/github.png" 
            alt="" 
            class="p-2" 
            >
        </a>
        <a  href="https://www.linkedin.com/in/jo%C3%A3o-vitor-denari-dos-santos/" 
            class="" 
            target="_blank" 
        >
            <img src="../assets/contact-icons/linkedin.png" 
            alt="" 
            class="p-2" 
            >
        </a>
        <a  href="mailto:joao.denari@hotmail.com" 
            class="" 
            target="_blank" 
        >
            <img src="../assets/contact-icons/mail.png" 
            alt="" 
            class="p-2" 
            >
        </a>
        <a  href="https://www.instagram.com/joaodenari/" 
            class="" 
            target="_blank" 
        >
            <img src="../assets/contact-icons/instagram.png" 
            alt="" 
            class="p-2" 
            >
        </a>
    </div>
</template>
<script>
    export default {
        name: 'MainFooter',
    }
</script>