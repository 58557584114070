<template>
    <div class="container"> 
        <ProfileForm />
    </div>
</template>

<script>
import ProfileForm from '../../components/ClientPage/Profile/ProfileForm.vue'
export default {
    name: 'UpdateProfile',
    components: {
        ProfileForm
    }
}
</script>