<template>
    <div class="container d-flex p-3">
        <div class="col-12 col-xl-6 p-5 text-center text-xl-start m-auto">
            <div class="h1 m-2 mb-4">A Routing Software to help you!</div>
            <div class="lead m-2 mt-3">Here you will find the best application to calculate your best route!</div>
            <div class="lead m-2 mt-3">No matter how many addresses you have, we calculate for you the shortest path reducing the distance traveled, your working time and fuel costs.</div>
            <div class="lead m-2 mt-3 w-100">Come check it out!</div>
        </div>
        <div class="col-6 p-3 d-none d-xl-block">
            <img src="../../assets/main-image.png" alt="" class="img-responsive w-100">
        </div>
    </div>
</template>
    <script>
        export default {
        name: 'IntroSection',
    }
</script>